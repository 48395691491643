import { useGetHokim } from "../../hooks/query";
import { Link } from "react-router-dom";
import { Loader } from "@mantine/core";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FaTelegramPlane } from "react-icons/fa";

const Hokim = () => {
  const useGetHokimInfo = useGetHokim();
  const { t } = useTranslation();
  if (useGetHokimInfo.isLoading) {
    return (
      <div className="flex justify-center mt-[250px]">
        <Loader color="#014f86" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div className="bg-[#f6f6f6] shadow-xl">
      {useGetHokimInfo.data?.map((item) => (
        <div key={item.id} className="text-white">
          <p className="text-center pt-[10px] font-semibold text-2xl text-black">
            {t("Piskent tumani hokimi")}
          </p>
          <div className=" pt-2 pb-8 mx-2 h-[553px]">
            <div className="mx-4">
              <img
                src={item.images}
                alt="hokim"
                height={340}
                width={330}
                className="flex justify-center mx-auto mt-2"
              />
            </div>
            <div className="flex flex-col items-center text-black">
              <h4 className="text-[22px] text-center mt-[10px] ">
                {item.f_name}
              </h4>
            </div>
            <div className="flex justify-center bg-gray-300 hover:bg-[#eef2ff] duration-300 w-[200px] 
            mx-auto mt-[20px] py-2 rounded-lg cursor-pointer">
              <Link to={`/murojaat/${item.id}`}>
                <button className="text-black ">
                  {t("Murojaat yuborish")}
                </button>
              </Link>
            </div>
            <div className="mt-[10px]">
              <div className="flex justify-center bg-[#1da1f2] hover:bg-[#0088cc] duration-300 w-[200px] mx-auto py-2 rounded-lg cursor-pointer">
                <a href="https://t.me/Hokimiyatga_murojaat_bot" target="_blank">
                  <button className="text-white flex justify-between items-center gap-4">
                    {t("Murojaat bot")} <FaTelegramPlane size={20} />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Hokim;
